import { useEffect } from "react";

const useCtrlS = (callMethodIfCtrlS) => {
    useEffect(() => {
        const keyDown = (event) => {
            if (event.key === "s" && event.ctrlKey) {
                event.preventDefault();
                callMethodIfCtrlS();
            }
        };

        document.addEventListener("keydown", keyDown);

        return () => {
            document.removeEventListener("keydown", keyDown);
        };
    });
};

export default useCtrlS;