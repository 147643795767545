import { Typography } from "@mui/material";
import { Link as L } from "react-router-dom";

const ProjectLink = (ProjectLinkProps) => {
    const {
        title,
        index,
        ProjectID,
        ArticleIndex
    } = ProjectLinkProps

    return (
        <L to="/project"
            state={{
                ProjectID: ProjectID,
                ArticleIndex: ArticleIndex
            }}
            key={index}
            m="auto"
        >
            <Typography sx={{
                color: 'white',
                textDecoration: 'underline'
            }}
            variant="outlined"
            >
                {title}
            </Typography>
        </L>
    )
}

export default ProjectLink