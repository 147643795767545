import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useAuth from './useAuth';
import useRefreshToken from './useRefreshToken';
import useUser from './useUser';

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const [operations] = useUser();
    const { auth, setAuth, persist } = useAuth();

    useEffect(() => {
        let isMounted = true;

        const checkPersistLogin = async () => {
            if (operations.getUserRole()) {
                try {
                    await refresh();
                }
                catch (err) {
                    ////console.log(err);
                }
            }
            isMounted && setIsLoading(false);
        }
        checkPersistLogin()
        return () => isMounted = false;
    }, [])

    return (
        <>
            {
                !persist
                    ? <Outlet />
                    : isLoading ?
                        <p>Loading...</p> :
                        <Outlet />
            }
        </>
    )
}

export default PersistLogin;