const blockWrapper = {
    width: '100%',
    height: '80vh',
    zIndex: 1,
}

const videoBlockStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover', 
    zIndex: 1,
}

const VideoBlock = ({children}) => {
    return (
        <div style={blockWrapper}>
            <video 
                style={videoBlockStyle}
                src={'https://s3.us-east-2.amazonaws.com/ytwang-my-code-blog.net/circles1080.mp4'}
                autoPlay
                loop
                muted />
            {children}
        </div>
    )
}

export default VideoBlock;