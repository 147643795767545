import { useEffect, useState } from "react";
import useAxiosFunction from './useAxiosFunction';
import isDev from '../helper/isDev';
import useAuth from "./useAuth";
import useAxiosPrivate from "./useAxiosPrivate";
import { DataStore } from '@aws-amplify/datastore';
import { Blogs } from '../models';
import { Predicates } from "aws-amplify";

//Either get all, or get a user's project overview
// /getAllProject or getUserProjects
const useOverview = (props) => {
    const {
        author,
        authorSub,
    } = props
    const [overview, setOverview] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const { auth } = useAuth();

    useEffect(() => {
        getOverview()
    }, [])

    const getOverview = async () => {
        setLoading(true);
        await DataStore.query(Blogs, (blogs) => blogs.and(blogs => [
            blogs.Author.eq(author),
            blogs.AuthorSub.eq(authorSub)
        ]), {
            page: 0,
            limit: 100
        }).then((res) => {
            projectToOverview(res);
            setLoading(false);
        });
    }

    const projectToOverview = (response) => {
        let projectMapping = {}
        //console.log("Overview : " ,response);
        response.map((value) => {
            if (projectMapping[value.ProjectID] === undefined) {
                projectMapping[value.ProjectID] = {}
                projectMapping[value.ProjectID]['blogs'] = {}
                if (Number(value.ArticleIndex) === -1) {
                    projectMapping[value.ProjectID]['ProjectDescription'] = value.ProjectDescription
                    projectMapping[value.ProjectID]['Author'] = value.Author
                    projectMapping[value.ProjectID]['RepoLink'] = value.githubLink
                    projectMapping[value.ProjectID]['DemoLink'] = value.demoLink
                    projectMapping[value.ProjectID]['ProjectTitle'] = value.ProjectTitle
                    projectMapping[value.ProjectID]['AuthorSub'] = value.AuthorSub
                } else {
                    let tasks = {}
                    tasks[value.ArticleIndex] = {
                        title: value.ArticleTitle,
                        Last_Modified: Number(value["_lastChangedAt"])
                    }
                    projectMapping[value.ProjectID] = {
                        blogs: tasks,
                    }
                }
            } else if (!Array.isArray(projectMapping[value.ProjectID]) || !projectMapping[value.ProjectID].length) {
                if (Number(value.ArticleIndex) === -1) {
                    projectMapping[value.ProjectID]['ProjectDescription'] = value.ProjectDescription
                    projectMapping[value.ProjectID]['Author'] = value.Author
                    projectMapping[value.ProjectID]['RepoLink'] = value.githubLink
                    projectMapping[value.ProjectID]['DemoLink'] = value.demoLink
                    projectMapping[value.ProjectID]['ProjectTitle'] = value.ProjectTitle
                    projectMapping[value.ProjectID]['AuthorSub'] = value.AuthorSub
                } else{
                    projectMapping[value.ProjectID].blogs[value.ArticleIndex] = {
                        title: value.ArticleTitle,
                        Last_Modified: Number(value["_lastChangedAt"])
                    }
                }
            }
        })
        setOverview(Object.entries(projectMapping).map(([key, value]) => {
            return {
                ProjectID: key,
                projectTitle: value.ProjectTitle,
                projectTask: Object.entries(value['blogs']).map(
                    ([key, value]) => {
                        return {
                            "ArticleIndex": key,
                            "title": value.title,
                            "Last_Modified": value.Last_Modified
                        }
                    }
                ),
                projectDescription: value.ProjectDescription,
                Author: author,
            }
        }))
    }

    return [overview, error, loading, getOverview]
}

export default useOverview;