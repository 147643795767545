import { Auth } from "aws-amplify";


const useRefreshToken = () => {
    const refresh = async () => {
        try {
            const session = await Auth.currentSession();
            if (session.isValid() === false) {
                // Access token is expired, refresh the token
                await Auth.refreshSession(
                    session.getRefreshToken()
                );
            }
        } catch (error) {
            //console.log('Error:', error);
        }
    }
    return refresh
}

export default useRefreshToken;