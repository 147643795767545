import {
    Box,
    Grid,
    Modal,
    Button,
    Typography
} from "@mui/material";
import { useState } from 'react';

const DeleteConfirmationForm = (DeleteConfirmationFormProps) => {
    const {
        open,
        blogsToDelete,
        blogs,
        onClose,
        onSubmit
    } = DeleteConfirmationFormProps

    const [projectTitle, setProjectTitle] = useState("");
    const [blogsForDeletion, setBlogsForDeletion] = useState([]);

    const style = {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        p: 4,
    };
    useState(() => {
        setBlogsForDeletion(blogsForDeletion)
    }, [blogsToDelete])

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    bgcolor: 'background.paper',
                    p: 4,
                    width: "100%",
                }}
                    component="form">
                    <Grid sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                        width: "100%",
                        paddingBottom: 2,
                    }}>
                        <Typography>Are you sure you want to delete the following?</Typography>
                        {
                            blogs.map((item) => {
                                let projectTitle = item?.projectTitle
                                return (item.projectTask.filter((item) => {
                                    if (blogsToDelete[projectTitle]) {
                                        return (blogsToDelete[projectTitle][item.ArticleIndex])
                                    }
                                    return false
                                }
                                ).map((item, index) => {
                                    return (
                                        <Typography key={index}>{projectTitle}-{item.title}</Typography>
                                    )
                                })
                                )
                            })
                        }
                    </Grid>
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        bgcolor: 'background.paper',
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingTop: 2,
                    }}
                        container
                    >
                        <Button variant="contained" type="submit"
                            sx={{
                                marginRight: 2
                            }}
                            onClick={(event) => {
                                event.preventDefault()
                                onSubmit(blogsForDeletion)
                                onClose()
                            }}>
                            Yes
                        </Button>
                        <Button variant="contained"
                            onClick={(event) => {
                                event.preventDefault()
                                onClose()
                            }}>
                            No
                        </Button>
                    </Grid>

                </Grid>
            </Box>
        </Modal>
    )
}

export default DeleteConfirmationForm
