import UserList from "./components/UserList";
import AddUser from "./components/AddUser";
import Container from '@mui/material/Container';
import useAxiosFunction from "../../hooks/useAxiosFunction";

const Admin = () => {
    const [response,status, error, loading, axiosFetch] = useAxiosFunction();

    //Some kind of search method 

    return (
        <>
            <div className="CenterWrapper">
                <Container>
                    <AddUser/>
                    <UserList/>
                </Container>
            </div>
        </>
    )

}

export default Admin