import RenderIf from "../../../components/RenderIf";
import { Modal, Box, Grid, Button, Typography, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { Storage } from "@aws-amplify/storage"
import MediaBlock from "./mediaBlock";
import { Buffer } from "buffer";
import { FileUploader } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

const UploadFile = (props) => {
    const {
        renderCondition,
        open,
        onClose,
        onSubmit
    } = props

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        p: 4,
    };

    const [mediaKey, setMediaKey] = useState("");

    const createMediaBlock = () => {
        onSubmit({
            mediaKey: mediaKey,
        })
        setMediaKey("")
    }


    const [fileState, setFileState] = useState({});

    return (
        <RenderIf condition={renderCondition}>
            <Modal
                open={open ? open : false}
                onClose={() => {
                    onClose()
                    setMediaKey("")
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                        p: 4,
                        width: "100%",
                    }}
                        component="form">
                        <Grid sx={{
                            display: 'inline-flex',
                            flexDirection: 'column',
                            bgcolor: 'background.paper',
                            width: "100%",
                            paddingTop: 2,
                        }}>
                            <FileUploader
                                accessLevel="protected"
                                acceptedFileTypes={["image/*", "video/*", "audio/*"]}
                                maxFileCount={1}
                                maxHeight={1600}
                                maxWidth={1600}
                                onSuccess={({key})=>{
                                    setMediaKey(key)
                                }}
                            />
                            <Button variant="contained" type="file"
                                disabled={(mediaKey === "")}
                                onClick={(event) => {
                                    event.preventDefault()
                                    createMediaBlock()

                                }}>
                                Create Media Block
                            </Button>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
        </RenderIf>
    )
}

export default UploadFile;