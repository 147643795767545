import { Grid, Box, Toolbar, Button, TextField, FormGroup, FormControlLabel, Typography } from '@mui/material';
import "../../styles/login.css"
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import useUser from '../../hooks/useUser';
import { Hub } from 'aws-amplify';
import RenderIf from '../../components/RenderIf';

const LoginPage = () => {

    const navigate = useNavigate();
    const thislocation = useLocation();
    const from = thislocation.state?.from || "/";
    const query = thislocation.state?.query || {};
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [operations] = useUser();

    const [errorContext, setErrorContext] = useState("");
    const [errorBox, setErrorBox] = useState(true);

    useEffect(() => {
        //if user is already login, user should not be able to access this page.

        const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
            const user = data;
            switch (event) {
                case "signIn":
                    operations.getUserRole()
                    navigate('/')
                    break;
            }
        });
        return unsubscribe;
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        signIn({
            username: user,
            password: password
        })
    }

    async function signIn(props) {
        const {
            username,
            password
        } = props
        try {
            await Auth.signIn(username, password)
        } catch (error) {
            if (error.name == "UserNotFoundException") {
                setErrorContext("Login credential does not exist in the system")
            }
            else if (error.name == "NotAuthorizedException") {
                setErrorContext("Login credential does not match with the system")
            } else if (error.name == "UserNotConfirmedException") {
                navigate('/emailVerification', { state: { user: user.attributes.email, usage: "verifyEmail" } })
            }
            setErrorBox(true);
            // //console.log('error signing in', error);
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            flexWrap: 'wrap',
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Toolbar />
            <Toolbar />

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    display: 'inline-flex',
                    width: '500px',
                    padding: '20px',
                    borderRadius: '25px',
                    backgroundColor: "#272828"
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'white'
                    }}
                    alignItems="center"
                    justifyContent="center">
                    <RenderIf condition={errorBox}>
                        <Typography sx={{
                            color: "red"
                        }}>{errorContext}</Typography>
                    </RenderIf>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'white'
                    }}
                    alignItems="center"
                    justifyContent="center">
                    <FormGroup>
                        <FormControlLabel control={
                            <TextField id="outlined-basic"
                                variant="outlined"
                                placeholder="Enter Username"
                                onChange={(event) =>
                                    setUser(event.target.value)
                                }
                                sx={{
                                    marginLeft: '5%',
                                    input: { color: 'white' }
                                }} />}
                            label="Username"
                            labelPlacement="start" />
                        <FormControlLabel control={
                            <TextField id="outlined-basic"
                                variant="outlined"
                                type="password"
                                placeholder="Enter Password"
                                onChange={(event) =>
                                    setPassword(event.target.value)
                                }
                                sx={{
                                    marginLeft: '6%',
                                    input: { color: 'white' }
                                }}
                            />}
                            label="Password"
                            labelPlacement="start" />

                    </FormGroup>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            color: 'white',
                        }}
                        alignItems="center"
                        justifyContent="center">
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                borderWidth: "3px",
                                borderColor: "white",
                                marginRight: '15px'
                            }}
                            onClick={handleSubmit} >
                            Submit
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            component={Link}
                            to={'/signup'}
                            sx={{
                                borderWidth: "3px",
                                borderColor: "white"
                            }}>
                            Sign Up
                        </Button>
                    </Box>
                </Box>
            </Grid>

        </Box>
    )
}

export default LoginPage