import QueueIcon from '@mui/icons-material/Queue';
import {Box, Button, InputAdornment, TextField } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useState } from "react";


const ProjectToolBar = (toolBarMethod) => {
    const {
        addProject,
        removeProject,
        removeAble
    } = toolBarMethod

    const [searchTerm, setSearchTerm] = useState("");
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <Box sx={{
            display: 'inline-flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
        }}
            direction="column"
            alignItems="center"
            justifyContent="center"
            margin="auto"
        >
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    width: '50%'
                }}>
                <TextField
                    value={searchTerm}
                    onChange={handleChange}
                    placeholder="Search…"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        input: {
                            color: 'white',
                        },
                    }}
                    size="small"
                />
            </Box>
            <Button color="inherit"
                startIcon={<DisabledByDefaultIcon />}
                onClick={removeProject}
                disabled={removeAble}
                variant="contained"
            >
                Remove Project
            </Button>
            <Button color="inherit"
                startIcon={<QueueIcon />}
                onClick={addProject}
                variant="contained"
            >
                Add Project
            </Button>
        </Box>
    )
}


{/* <Stack sx={{
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%'
}}>            </Stack> */}

export default ProjectToolBar