import { Editor } from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useRef, useState, useEffect } from 'react';
import MediaBlock from './mediaBlock';


//CKEditor.builtinPlugins.map( plugin => plugin.pluginName );
const GridComponent = ({ content, keyID, draggable, onOverflow, updateComponent, readOnly }) => {
    const editorRef = useRef(null);
    const [data, setData] = useState();
    const [thisID, setID] = useState('');

    useEffect(() => {
        setID(keyID)
        console.log("Content : ", content)
        setData(content)
    }, [content])

    const handleEditorReady = (editor) => {
        editorRef.current = editor;
        handleReadOnly(readOnly)
        let editableElement = editor.editing.view.getDomRoot();
        const observer = new ResizeObserver(entry => {
            console.log("Entry : ", entry)
            if (entry[0].target.parentElement.clientHeight < entry[0].contentRect.height) {
                let diff = (entry[0].contentRect.height - entry[0].target.parentElement.clientHeight)
                onOverflow(keyID, Math.ceil(diff / 25))
            }

        });
        observer.observe(editableElement);
    };

    const handleReadOnly = (value) => {
        if (value === true) {
            editorRef.current.enableReadOnlyMode(keyID);
        } else if (value === false) {
            editorRef.current.disableReadOnlyMode(keyID);
        }
    }

    useEffect(() => {
        if (editorRef.current != null) {
            handleReadOnly(readOnly)
        }
    }, [readOnly])
    
    return (
        <CKEditor
            editor={Editor}
            data={data}
            onReady={handleEditorReady}
            onChange={(event, editor) => {
                setData(editor.getData());
                updateComponent(thisID, editor.getData());
            }}
            onBlur={(event, editor) => {
                draggable(true);
            }}
            onFocus={(event, editor) => {
                draggable(false);
            }}
        />
    )

}


export default GridComponent

