import useMouseCoord from '../../../hooks/useMouseCoord'
import { useRef, useEffect, useState } from 'react';
import ImageMapper from 'react-img-mapper';
import { Box,Typography } from '@mui/material';
import RenderIf from '../../../components/RenderIf';

const TechBlock = () => {
    const mousePos = useMouseCoord();
    const myRef = useRef();

    const [imgMap, setImgMap] = useState({
        names: "test",
        areas: []
    });

    const [imgSize, setImgSize] = useState([500, 897]);

    const mapConfig = {
        "shape": "poly",
        "fillColor": "#eab54d4d",
        "strokeColor": "blue",
    }

    const flexBoxStyle = {
        color: 'white',
        width: '100%',
        height: '100%',
        borderRadius: '25px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        flexWrap: 'wrap',
        m: '2%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 10000
    }

    const imgContainer = {
        opacity: '1',
    }

    const [hoverOn, setHoverOn] = useState(true);

    const [renderSkills, setSkills] = useState({
        top: window.innerWidth > 480 ? false : true,
        mid: window.innerWidth > 480 ? false : true,
        bot: window.innerWidth > 480 ? false : true
    })
    const techStackImgCoord = {
        top: [[0.4750, 0.082], [0.3050, 0.2121], [0.3050, 0.22], [0.3050, 0.33],
        [0.475, 0.449], [0.51, 0.449], [0.68, 0.33], [0.68, 0.21], [0.5050, 0.082]],
        mid: [[0.3050, 0.332], [0.3050, 0.544], [0.479, 0.670], [0.509, 0.670],
        [0.68, 0.544], [0.68, 0.332], [0.509, 0.452], [0.479, 0.452]],
        bot: [[0.3050, 0.548], [0.3050, 0.822], [0.488, 0.945],
        [0.505, 0.945], [0.68, 0.822], [0.68, 0.548], [0.505, 0.67], [0.478, 0.67]]
    }


    const calculateCoord = (coord) => {

        if (window.innerWidth <= 480 || myRef.current === undefined) return
        if (myRef.current.parentElement === null) return
        let width = Number(window.innerWidth / 4);
        let height = Number(width * 0.8);
        // const x = myRef.current.offsetLeft;
        // const y = myRef.current.offsetTop;
        setImgSize([width, height])
        let Areas = []

        Object.keys(techStackImgCoord).map((key, index) => {
            let coords = []
            let polygon = techStackImgCoord[key].map((item) => {
                // returnStr = returnStr + parseInt((Number(item[0]) * Number(width))) + ","
                // returnStr = returnStr + parseInt((Number(item[1]) * Number(height))) + ","
                coords = coords.concat(parseInt((Number(item[0]) * Number(width))))
                coords = coords.concat(parseInt((Number(item[1]) * Number(height))))
                return [parseInt((Number(item[0]) * Number(width))),
                parseInt((Number(item[1]) * Number(height)))]
            })

            Areas = Areas.concat([{
                ...mapConfig,
                'name': key,
                'coords': coords,
                'polygon': polygon
            }])
        })

        // returnStr = returnStr.slice(0, -1)
        // setTopStack(returnStr)
        setImgMap({
            name: 'my-Map',
            areas: Areas
        })

    }

    const renderHover = () => {
        let list = Object.keys(renderSkills).map((key, index) => {
            return renderSkills[key]
        })
        return list.every((item) => { return !item })
    }

    const handleMouseEnter = (props) => {
        let skills = { ...renderSkills }
        skills[props.name] = true
        setSkills(skills)
        setHoverOn(false)
        // let container = { ...containerStyle }
        // container['zIndex'] = 1
        // setContainerStyle(container)
    }

    const handleMouseLeave = (props) => {
        let skills = { ...renderSkills }
        skills[props.name] = false
        setSkills(skills)
        setHoverOn(true)

        // let container = { ...containerStyle }
        // container['zIndex'] = 0
        // setContainerStyle(container)
    }

    useEffect(() => {
        calculateCoord()
        window.addEventListener("resize", () => {
            if (window.innerWidth <= 480) {
                setSkills({
                    top: true,
                    mid: true,
                    bot: true
                })
            } else {
                setSkills({
                    top: false,
                    mid: false,
                    bot: false
                })
            }

            calculateCoord()
        });
    }, [myRef.current]);

    return (
        <div style={{
            height: '100%',
            width: '100%',
            position: 'relative'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
            }}>
                <Typography sx={{
                    color: "white",
                    fontSize: window.innerWidth <= 480 ? '4vh' : '2rem',
                }}
                    gutterBottom={true}
                    noWrap={true}
                    variant='overline'
                    align='left'>
                    My Tech Stack
                </Typography>

            </Box>
            <Box>
                <Box sx={{
                    height: window.innerWidth > 480 ? '150px' : '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    marginLeft: window.innerWidth > 480 ? '30%' : '5%',
                    marginRight: window.innerWidth > 480 ? '30%' : '5%',
                }}>
                    <RenderIf condition={hoverOn && (window.innerWidth > 480)}>
                        <Box sx={{
                            ...flexBoxStyle,
                        }}>
                            <Typography sx={{
                                marginRight: '5%',
                            }}>
                                Hover Over The Stack Below
                            </Typography>
                        </Box>
                    </RenderIf>
                    <RenderIf condition={renderSkills.top}>
                        <Box sx={{
                            ...flexBoxStyle,
                        }}>
                            <Typography
                                align='center'
                                sx={{
                                    width: '100%',
                                }}>
                                Front End:
                            </Typography>
                            <img src="https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white" alt="html" />
                            <img src="https://img.shields.io/badge/REACT-61DAFB?style=for-the-badge&logo=react&logoColor=white" alt="React" />
                        </Box>
                    </RenderIf>
                    <RenderIf condition={renderSkills.mid}>
                        <Box sx={{
                            ...flexBoxStyle,
                        }}>
                            <Typography
                                align='center'
                                sx={{
                                    width: '100%',
                                }}>
                                DevOps:
                            </Typography>
                            <img src="https://img.shields.io/badge/SELENIUM-43B02A?style=for-the-badge&logo=selenium&logoColor=white" alt="selenium" />
                            <img src="https://img.shields.io/badge/CUCUMBER-61DAFB?style=for-the-badge&logo=cucumber&logoColor=white" alt="cucumber" />
                            <img src="https://img.shields.io/badge/POSTMAN-FF6C37?style=for-the-badge&logo=postman&logoColor=white" alt="postman" />
                            <img src="https://img.shields.io/badge/jenkins-D24939?style=for-the-badge&logo=jenkins&logoColor=white" alt="jenkins" />
                            <img src="https://img.shields.io/badge/AWS-232F3E?style=for-the-badge&logo=amazonaws&logoColor=white" alt="amazonaws" />
                            <img src="https://img.shields.io/badge/github-181717?style=for-the-badge&logo=github&logoColor=white" alt="amazonaws" />
                        </Box>
                    </RenderIf>
                    <RenderIf condition={renderSkills.bot}>
                        <Box sx={{
                            ...flexBoxStyle,
                        }}>
                            <Typography
                                align='center'
                                sx={{
                                    width: '100%',
                                }}>
                                Back End & Languages :
                            </Typography>
                            <img src="https://img.shields.io/badge/springboot-6DB33F?style=for-the-badge&logo=springboot&logoColor=white" alt="springboot" />
                            <img src="https://img.shields.io/badge/flask-000000?style=for-the-badge&logo=flask&logoColor=white" alt="flask" />
                            <img src="https://img.shields.io/badge/pandas-150458?style=for-the-badge&logo=pandas&logoColor=white" alt="pandas" />
                            <img src="https://img.shields.io/badge/c++-A8B9CC?style=for-the-badge&logo=cplusplus&logoColor=white" alt="cplusplus" />
                            <img src="https://img.shields.io/badge/python-3776AB?style=for-the-badge&logo=python&logoColor=white" alt="python" />
                            <img src="https://img.shields.io/badge/Java-000000?style=for-the-badge&logo=java&logoColor=white" alt="Java" />
                        </Box>
                    </RenderIf>
                </Box>
                <RenderIf condition={window.innerWidth > 480}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'flex-start'
                    }}>
                        <ImageMapper
                            containerRef={myRef}
                            width={imgSize[0]}
                            height={imgSize[1]}
                            src={'https://s3.us-east-2.amazonaws.com/ytwang-my-code-blog.net/techStack3.png'} map={imgMap}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                    </Box>
                </RenderIf>

            </Box>
        </div >
    )
}

export default TechBlock

