import "../../styles/projectpage.css";
import PageLayout from "./components/PageLayout";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import usePage from "../../hooks/usePage";
import useAuth from "../../hooks/useAuth";
import ProjectDrawer from "./components/ProjectDrawer";
//Response have a prerloaded layout.
// JSON should be an array of layout
//  [{
//      i: "a",
//      x:,
//      y:,
//      w:,
//      h:,
//      content:
//  },...]

const ProjectPage = () => {
    const location = useLocation();
    const [ProjectID, setProjectID] = useState(location.state.ProjectID)
    const [ArticleIndex, setArticleIndex] = useState(location.state.ArticleIndex)

    const [project, setProject] = useState([]);
    const [blog, setBlog] = useState(undefined);
    const [projectDrawer, setProjectDrawer] = useState();
    const [projectAuthor, setProjectAuthor] = useState(undefined);
    const [pageResponse, pageError, pageLoading, ops] = usePage();

    const { auth } = useAuth();

    useEffect(() => {
        ops.getProject({
            ProjectID: ProjectID
        }).then(()=>{
            displayBlog({
                newArticleIndex: ArticleIndex
            })
        })
    }, [])

    const onEditBlog = (blog) => {
        const {
            layout,
            content,
            title,
            author,
            authorSub
        } = blog
        ops.updateBlog({
            ProjectID: ProjectID,
            ArticleIndex: ArticleIndex,
            Author: author,
            AuthorSub: authorSub,
            Title: title,
            Content: layout.lg.map((item) => {
                return (
                    {
                        ...item,
                        Content: content[item.i]
                    }
                )
            })
        })
        //Need a setBlog here
    }

    const displayBlog = (BlogProps) => {
        const {
            newArticleIndex
        } = BlogProps
        let currentBlog = project.filter((item) => item.ArticleIndex === newArticleIndex)
        setArticleIndex(newArticleIndex)
        setBlog(currentBlog[0])
    }

    const onAddBlogToProject = (BlogProps) => {
        const {
            newBlogTitle,
            ArticleIndex
        } = BlogProps
        if (newBlogTitle.replace(/\s/g, "").length > 0) {
            ops.addBlogToProject({
                ProjectID: ProjectID,
                blogTitle: newBlogTitle,
                author: auth.user,
                authorSub: auth.userSub,
                ArticleIndex: ArticleIndex
            })
        }
        setArticleIndex(ArticleIndex)
    }

    useEffect(() => {
        if (pageResponse != undefined) {
            let currentBlog = pageResponse.filter((item) => item.ArticleIndex == ArticleIndex)
            setProject(pageResponse)
            setBlog(currentBlog[0])
            setProjectDrawer(pageResponse)
            setProjectAuthor(currentBlog[0].Author)
        }
    }, [pageResponse])

    return (
        <>
            <div style={{
                display: "flex",
                alignItems: "center",
                width: "85%",
                height: "100%"
            }}>
                <ProjectDrawer blogs={projectDrawer}
                    addBlog={onAddBlogToProject}
                    displayBlog={displayBlog}
                    allowedUser={projectAuthor} />
                <PageLayout
                    allowedRoles={['User', 'Admin']}
                    response={blog}
                    onSave={onEditBlog}></PageLayout>
            </div >
        </>
    )
}

export default ProjectPage


