import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/navbar';
import ProjectPage from './pages/Project/project';
import LoginPage from './pages/Login/login';
import SignUp from './pages/SignUp/signup';
import Verify from './pages/Login/verify';
import Unauthorized from './pages/Errors/Unauthorized';
import Admin from './pages/Admin/Admin';
import Missing from './pages/Errors/Missing';
import RequireAuth from './helper/RequireAuth';
import { AuthProvider } from './helper/AuthProvider';
import HomePage from './pages/Home/home';
import AboutPage from './pages/About/about';
import GalleryPage from './pages/Gallery/gallery';
import UserGallery from './pages/Gallery/userGallery';
import PersistLogin from './hooks/PersistLogin';
// import background from '';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Toolbar } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#696b6a'
    },
    secondary: {
      main: '#fff',
    },
    checkBox: {
      main: '#000'
    }
  }
});

function App() {

  return (
    <div style={{
      width: '100%',
      backgroundImage: `url(${'https://s3.us-east-2.amazonaws.com/ytwang-my-code-blog.net/cbackground.png'})`,
      backgroundRepeat: 'repeat',
      zIndex: 0
    }}>
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <NavBar />
            <Toolbar />
            <Routes>
              {/* Public Routes*/}
              <Route element={<PersistLogin />}>
                <Route path="/" element={<HomePage />}></Route>
                <Route path="/home" element={<HomePage />}></Route>
                <Route path="/about" element={<AboutPage />}></Route>
                <Route path="/projects" element={<GalleryPage />}></Route>
                <Route path="/project" element={<ProjectPage />}></Route>
                <Route path="/login" element={<LoginPage />}></Route>
                <Route path="/unauthorized" element={<Unauthorized />}></Route>
                <Route path="/signup" element={<SignUp />}></Route>
                <Route path="/emailVerification" element={<Verify />}></Route>
                <Route path="/emailMFA" element={<Verify />}></Route>

                {/* Private Routes*/}
                <Route element={<RequireAuth allowedRoles={['User', 'Admin']} />}>
                  <Route path="/myProjects" element={<UserGallery />}></Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={['Admin']} />}>
                  <Route path="/admin" element={<Admin />}></Route>
                </Route>
              </Route>
              {/* Catch all */}
              <Route path="/*" element={<Missing />}></Route>
            </Routes>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
