import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box'

const AddUser = () => {
    return (
        <>
            <Box justifyContent='space-between'
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                    width: '500px'
                }}>
                <FormControl variant="standard"
                    size='medium'
                    fullWidth={true}>
                    <FormLabel sx={{
                        color:'white'
                    }}>Add User</FormLabel>
                    <TextField 
                        placeholder="Enter User Email"
                        sx={{
                            "& label": {
                                "&.Mui-focused": {
                                    color: 'white'
                                }
                            },
                            input:{
                                color: 'white'
                            }
                        }}
                    />
                </FormControl>
                <Button variant='contained'
                    sx={{
                        height: "40px",
                        width: "150px",
                        m: 3,
                    }}>Add User</Button>
            </Box>
        </>
    )
}

export default AddUser