import * as React from 'react';
import useOverview from '../../hooks/useOverview'
import useAuth from '../../hooks/useAuth';
import usePage from '../../hooks/usePage';
import ProjectOverview from './components/ProjectOverview';
import ProjectToolBar from './components/ProjectToolBar';
import { Stack, Box,Toolbar } from '@mui/material';
import CreateProjectForm from './components/CreateProjectForm';
import { useState, useEffect } from 'react';


const UserGallery = () => {

    const { auth } = useAuth();
    const [response, error, loading, loadOverview] = useOverview({
        author: auth.user,
        authorSub: auth.userSub
    });

    const [overview, setOverview] = useState([]);
    const [openCreateProject, setOpenCreateProject] = useState(false);
    const [openDeleteForm, setOpenDeleteForm] = useState(false);
    const [pageResponse, pageError, pageLoading, ops] = usePage();
    const [removeAble, setRemoveAble] = useState(true);

    useEffect(() => {
        setOverview(response)
    }, [response])

    const openCreateForm = () => {
        setOpenCreateProject(true);
    }

    const closeDeleteForm = () => {
        setOpenCreateProject(false);
    }

    const openDeleteConfirmForm = () => {
        setOpenDeleteForm(true);
    }
    const closeDeleteConfirmForm = () => {
        setOpenDeleteForm(false);
    }

    const addProject = (addProjectProps) => {
        const {
            projectTitle,
            firstBlogTitle,
            standAloneBlog,
            projectDescription,
            repoLink,
            demoLink
        } = addProjectProps

        ops.addProject({
            projectTitle: standAloneBlog ? auth.user : projectTitle,
            firstBlogTitle: firstBlogTitle,
            author: auth.user,
            authorSub: auth.userSub,
            projectDescription: projectDescription,
            repoLink: repoLink,
            demoLink: demoLink
        }).then((projectID) => {
            setOverview([...overview, {
                ProjectID: projectID,
                projectTitle: projectTitle,
                Author: auth.user,
                projectDescription: projectDescription,
                repoLink: repoLink,
                demoLink: demoLink,
                projectTask: [
                    {
                        ArticleIndex: "0",
                        title: firstBlogTitle,
                        Last_Modified: Math.round((new Date()).getTime() / 1000)
                    }
                ],
            }])
        })
        setOpenCreateProject(false);
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        }}>
            <Toolbar />
            <Stack sx={{
                width: '50%',
                height: '100%',
            }}
                spacing={3}
            >
                <CreateProjectForm open={openCreateProject}
                    onSubmit={addProject}
                    onClose={closeDeleteForm} />
                <ProjectToolBar addProject={openCreateForm}
                    removeProject={openDeleteConfirmForm}
                    removeAble={removeAble} />
                <ProjectOverview response={overview}
                    error={error}
                    loading={loading}
                    checkBox={true}
                    setRemoveAble={setRemoveAble}
                    deleteFormProps={{
                        open: openDeleteForm,
                        onClose: closeDeleteConfirmForm,
                    }}
                />
            </Stack>
        </Box>
    )
}

export default UserGallery