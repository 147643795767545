import * as React from 'react';
import { Box, Stack, Toolbar } from '@mui/material';
import useOverview from '../../hooks/useOverview'
import ProjectOverview from './components/ProjectOverview';
import axios from "../../apis/test";


const GalleryPage = () => {

    const [response, error, loading, getOverview] = useOverview({
        author: "Yu Wang",
        authorSub: "a10bc5e0-7011-7017-77b7-d7cc03eb6f02"
    });

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                backgroundSize: 'inherit',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
            }}>
            <Toolbar />
            <Stack sx={{
                width: '50%',
                height: '100%',
            }}>
                <ProjectOverview response={response}
                    error={error}
                    loading={loading}
                    checkBox={false}
                    setRemoveAble={() => { }}
                />
            </Stack>
        </Box>
    )
}

export default GalleryPage