import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import RenderIf from './RenderIf';
import { Button } from '@mui/material';
import { useEffect } from 'react';

const NavTab = ({
    url,
    children,
    item,
    query,
    allowedRoles,
    showIfLogin = true
}) => {
    const { auth } = useAuth();
    return (
        <RenderIf condition={
            auth?.roles ?    //If login or not
                (showIfLogin ?
                    (allowedRoles ?
                        (auth?.roles?.find(role => allowedRoles?.includes(role)))
                        : true)
                    : auth?.roles.includes('User') || auth?.roles.includes('Admin')?
                        false :
                        true)
                : allowedRoles ? false : true
        }>
            <Button component={Link}
                to={url}
                state={{
                    from: item,
                    query: query
                }}
                sx={{
                    color: '#fff',
                    fontSize: '20px'
                }}
            >
                {children}
            </Button>
        </RenderIf>
    )
};

export default NavTab