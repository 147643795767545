
export const defaultContent = [
    {
        "i": "a",
        "x": 0,
        "y": 0,
        "w": 7,
        "h": 7,
        "Content": "<p> Please Begin Your Editing Here <p>"
    }
]