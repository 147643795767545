import * as React from 'react';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Stack, Box, FormControlLabel, Checkbox, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import RenderIf from '../../../components/RenderIf';
import usePage from '../../../hooks/usePage';
import uuid from 'react-uuid';
import DeleteConfirmationForm from './DeleteConfirmationForm';
import ProjectLink from './ProjectLink';
import { fromUnixTime, format } from 'date-fns'
import GitHubIcon from '@mui/icons-material/GitHub';
import { TableContainer, TableHead, TableRow, Paper, Table, TableBody } from '@mui/material';
import StyledTableCell from '../../../components/StyledTableCell';
import StyledTableRow from '../../../components/StyledTableRow';


const ProjectOverview = (projectOverviewProps) => {
    const {
        response,
        error,
        loading,
        checkBox,
        setRemoveAble,
        deleteFormProps
    } = projectOverviewProps

    const { auth } = useAuth();
    const [pageResponse, pageError, pageLoading, ops] = usePage();
    const [checked, setChecked] = useState({});

    const [indeterminate, setIndeterminate] = useState({});
    const [accordion, setAccordion] = useState({});
    const [render, setRender] = useState(false);
    const [blogChecked, setBlogChecked] = useState({});
    const [overview, setOverview] = useState([]);

    const flexBoxStyle = {
        width: '100%',
        height: '100%',

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    }

    useEffect(() => {
        if (response.length > 0) {
            //console.log(response)
            let loadOverview = [...response]
            setOverview(loadOverview)
            let childDict = {};
            let checkDict = {};
            let indeterDict = {};
            let accordionDict = {};
            response.map((item) => {
                checkDict[item.projectTitle] = false
                indeterDict[item.projectTitle] = false
                childDict[item.projectTitle] = {}
                accordionDict[item.projectTitle] = false
                item.projectTask.map((task) => {
                    childDict[item.projectTitle][task.ArticleIndex] = false
                })
            })
            setBlogChecked(childDict)
            setChecked(checkDict)
            setIndeterminate(indeterDict)
            setAccordion(accordionDict)
            setRender(true);
        }
    }, [response])

    useEffect(() => {
        handleRemoveAble()
    }, [indeterminate])

    const handleRemoveAble = () => {
        let indete = Object.entries(indeterminate).map(
            ([key, value]) => {
                return value
            }
        )
        let proj = Object.entries(checked).map(
            ([key, value]) => {
                return value
            }
        )
        if (proj.some((val) => val)) {
            setRemoveAble(false)
        } else if (indete.some((val) => val)) {
            setRemoveAble(false)
        }
        else {
            setRemoveAble(true)
        }
    }

    const handleCheckProject = (projectTitle, checks) => {
        let blogs = blogChecked[projectTitle]
        Object.entries(blogs).map(
            ([key, value]) => {
                if (indeterminate[projectTitle]) {
                    blogs[key] = true
                } else {
                    blogs[key] = checks
                }
            }
        )

        setChecked({
            ...checked,
            [projectTitle]: checks
        })
        setIndeterminate({
            ...indeterminate,
            [projectTitle]: false
        })

    }

    const handleCheckBlog = (projectTitle, blogIndex, checksblog) => {

        let blogs = blogChecked[projectTitle]
        blogs[blogIndex] = checksblog
        let checks = Object.entries(blogs).map(
            ([key, value]) => {
                return value
            }
        )
        setAccordion({
            ...accordion,
            [projectTitle]: true
        })
        setChecked({
            ...checked,
            [projectTitle]: checks.every((val) => val)
        })
        setIndeterminate({
            ...indeterminate,
            [projectTitle]: checks.every((val) => val) ? false : (checks.some((val) => val))
        })

    }

    const RemoveSelectedProject = () => {
        let blogs = []
        let overviewChanges = []
        overview.map((item) => {
            let newOverview = { ...item }
            newOverview.projectTask =
                item.projectTask.map(
                    (task) => {
                        if (blogChecked[item.projectTitle][task.ArticleIndex]) {
                            blogs = blogs.concat({
                                ProjectID: item.ProjectID,
                                ArticleIndex: task.ArticleIndex,
                                title: task.title
                            })
                        } else {
                            return task
                        }
                    })
            //check if it is an empty project with no article
            if (newOverview.projectTask[0] != undefined) {
                overviewChanges.concat(newOverview)
            }
            if (checked[item.projectTitle]) {
                blogs = blogs.concat({
                    ProjectID: item.ProjectID,
                    ArticleIndex: -1,
                    title: ""
                })
            }
        })
        // setOverviewChanges(overviewChanges)
        ops.removeBlogs({
            projectBlogs: blogs,
            author: auth.user,
            authorSub: auth.userSub
        }).then(()=>{
            if(overviewChanges.length == 0){
                setRender(false);
            }
            setOverview(overviewChanges)
        })
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <DeleteConfirmationForm open={deleteFormProps ? deleteFormProps.open : false}
                onClose={deleteFormProps?.onClose}
                onSubmit={RemoveSelectedProject}
                blogsToDelete={blogChecked}
                blogs={response}
            />
            <Grid item xs container direction="column" spacing={2}
                sx={{
                    borderWidth: '15px',
                    border: 'solid black',
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',

                }}>
                <Stack spacing={2}
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.0)',
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}>
                    {
                        render ?
                            overview.map((item, index) => {
                                return (
                                    <Accordion key={item.ProjectID}
                                        expanded={accordion[item.projectTitle]}
                                        onClick={() => {
                                            setAccordion({
                                                ...accordion,
                                                [item.projectTitle]: !accordion[item.projectTitle]
                                            })
                                        }}
                                        sx={{
                                            backgroundColor: 'rgba(0, 0, 0, 0.0)',
                                        }}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{
                                                color: 'white',
                                            }}
                                        >
                                            <RenderIf condition={checkBox} fallback={<Typography>{item.projectTitle}</Typography>}>
                                                <FormControlLabel
                                                    label={item.projectTitle}
                                                    control={
                                                        <Checkbox
                                                            checked={checked[item.projectTitle]}
                                                            indeterminate={indeterminate[item.projectTitle]}
                                                            onChange={
                                                                (event) => {
                                                                    handleCheckProject(item.projectTitle, event.target.checked)
                                                                }
                                                            }
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                            </RenderIf>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{
                                            color: 'white'
                                        }}>
                                            {/*Project Description here */}
                                            <Box sx={{
                                                m: 2,
                                                marginTop: 0,
                                            }}>
                                                <Typography>
                                                    Project  Description : {item.projectDescription}
                                                </Typography>
                                            </Box>

                                            {/*Table of Content Starts here */}
                                            <TableContainer component={Paper}>
                                                <Table aria-label="customized table" sx={{
                                                    borderColor: 'black'
                                                }}>
                                                    <TableHead>
                                                        <TableRow >
                                                            <StyledTableCell size='small'>
                                                                Project Contents
                                                            </StyledTableCell>
                                                            <StyledTableCell align='right' size='small'>
                                                                Last Modified
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            item.projectTask.map((val, index) => {
                                                                if (val.ArticleIndex >= 0) {
                                                                    return (
                                                                        <StyledTableRow key={uuid()}>
                                                                            <StyledTableCell size='small'>
                                                                                <RenderIf condition={checkBox} fallback={
                                                                                    <ProjectLink
                                                                                        title={val['title']}
                                                                                        index={index}
                                                                                        ProjectID={item.ProjectID}
                                                                                        ArticleIndex={val["ArticleIndex"]}
                                                                                    />}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        label={
                                                                                            <ProjectLink
                                                                                                title={val['title']}
                                                                                                index={index}
                                                                                                ProjectID={item.ProjectID}
                                                                                                ArticleIndex={val["ArticleIndex"]}
                                                                                            />
                                                                                        }
                                                                                        control={
                                                                                            <Checkbox
                                                                                                checked={blogChecked[item.projectTitle] ? blogChecked[item.projectTitle][val["ArticleIndex"]] : false}
                                                                                                onChange={(event) => {
                                                                                                    handleCheckBlog(item.projectTitle, val["ArticleIndex"], event.target.checked)
                                                                                                }}
                                                                                                color="primary"
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                </RenderIf>
                                                                            </StyledTableCell>
                                                                            <StyledTableCell align='right' size='small'>
                                                                                {format(fromUnixTime(new Date(val.Last_Modified/1000)), 'MM/dd/yyyy H:mm a')}
                                                                            </StyledTableCell>
                                                                        </StyledTableRow>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }) :
                            <>
                                <div style={{
                                    color: 'white'
                                }}>
                                    <p>Hello, There are no project currently in the database or server is down</p>
                                    <p>But do check out my github repo for some of the projects I did.</p>
                                    <a target='_blank' href={"https://github.com/YuWang711"}>
                                        <Button
                                            sx={{
                                                backgroundColor: 'rgba(0,0,0,0)',
                                                color: 'white',
                                                zIndex: 6000,
                                            }}
                                        >
                                            <GitHubIcon size="large"
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                            />
                                            <Typography>
                                                Yu Wang
                                            </Typography>
                                        </Button>
                                    </a>
                                </div>
                            </>
                    }
                </Stack>
                <Stack spacing={2}>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default ProjectOverview



{/* <Stack spacing={2}>
{
    item.projectTask.map((val, index) => {
        return (
            <Box sx={{
                display: 'inline-flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
            }}
                key={index}>
                <Box
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        width: '50%',
                        marginLeft: '3%',

                    }}>
                    <RenderIf condition={checkBox} fallback={
                        <ProjectLink
                            title={val['title']}
                            index={index}
                            projectTitle={item.projectTitle}
                            ArticleIndex={val["ArticleIndex"]}
                        />}
                    >
                        <FormControlLabel
                            label={
                                <ProjectLink
                                    title={val['title']}
                                    index={index}
                                    projectTitle={item.projectTitle}
                                    ArticleIndex={val["ArticleIndex"]}
                                />
                            }
                            control={
                                <Checkbox
                                    checked={blogChecked[item.projectTitle] ? blogChecked[item.projectTitle][val["ArticleIndex"]] : false}
                                    onChange={(event) => {
                                        handleCheckBlog(item.projectTitle, val["ArticleIndex"], event.target.checked)
                                    }}
                                />
                            }
                        />
                    </RenderIf>

                    <RenderIf condition={((auth.roles >= 'User') &&
                        (auth.user === item.Author))}>
                        <IconButton aria-label="edit">
                            <EditNoteIcon />
                        </IconButton>
                    </RenderIf>
                </Box>

                <Typography sx={{
                    color: 'text.secondary',
                    marginTop: "auto",
                    marginBottom: "auto",
                    color: 'white'
                }}>
                    Last Modified: {format(fromUnixTime(item.Last_Modified), 'MM/dd/yyyy H:mm a')}
                </Typography>
                <RenderIf condition={((auth.roles >= 'User') &&
                    (auth.user === item.Author))}>
                    <Stack direction="row" spacing={1}>
                        <IconButton aria-label="edit">
                            <EditNoteIcon />
                        </IconButton>
                        <IconButton aria-label="delete"
                            onClick={ops.removeBlog}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </RenderIf>
            </Box>
        )
    })
}
</Stack> */}