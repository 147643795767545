import { Auth } from "aws-amplify";
import useAuth from './useAuth';

const useUser = () => {
    const { auth, setAuth } = useAuth();

    const operations = {
        getUserRole: async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
                setAuth({
                    roles: groups,
                    userSub: user.attributes.sub,
                    user: user.attributes.name
                })
            } catch (error) {
                if (error.message === 'not authenticated') {
                    //console.log('No authenticated user');
                    return false;
                }
                //console.log(error)
                return false;
            } finally {
                return true;
            }
        }
    }
    return [operations]
}

export default useUser;