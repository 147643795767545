import {
    Box,
    Grid,
    Modal,
    Switch,
    FormControlLabel,
    Button,
    FormControl,
    InputLabel,
    Input,
    FormHelperText,
    TextField,
} from "@mui/material";
import { useState } from 'react';
import RenderIf from "../../../components/RenderIf";

const CreateProjectForm = (createProjectFormMethods) => {
    const {
        open,
        onClose,
        onSubmit
    } = createProjectFormMethods

    const [inputData, setInputData] = useState({ standAloneBlog: false });
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        p: 4,
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Grid sx={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    bgcolor: 'background.paper',
                    p: 4,
                    width: "100%",
                }}
                    component="form">
                    <Grid sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                        width: "100%",
                        paddingBottom: 2,
                    }}>
                        <FormControlLabel control={<Switch value={inputData.standAloneBlog}
                            name="standAloneBlog"
                            onClick={(event) => {
                                setInputData({ ...inputData, [event.target.name]: !inputData.standAloneBlog })
                            }}
                        />}
                            label="Stand Alone Blog"
                        />
                        <FormHelperText >Toggle this if you are creating one blog
                            that is not associate to a project</FormHelperText>
                    </Grid>
                    <Grid sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                        width: "100%",
                        paddingBottom: 2,
                    }}>
                        <RenderIf condition={!inputData.standAloneBlog}>
                            <InputLabel>Project Title</InputLabel>
                            <Input
                                name="projectTitle"
                                onChange={(event) => {
                                    setInputData({ ...inputData, [event.target.name]: event.target.value })
                                }}
                            />
                        </RenderIf>
                    </Grid>
                    <Grid sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                        width: "100%",
                        paddingBottom: 2,
                    }}>
                        <InputLabel>{!inputData.standAloneBlog ?
                            "First Blog Title" : "Blog Title"}</InputLabel>
                        <Input name="firstBlogTitle"
                            onChange={(event) => {
                                setInputData({ ...inputData, [event.target.name]: event.target.value })
                            }}
                        />
                    </Grid>
                    <Grid sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                        width: "100%",
                        paddingBottom: 2,
                    }}>
                        <InputLabel>{"Project Description"}</InputLabel>
                        <TextField
                            id="standard-multiline-static"
                            multiline
                            rows={4}
                            defaultValue=""
                            variant="standard"
                            name="projectDescription"
                            onChange={(event) => {
                                setInputData({ ...inputData, [event.target.name]: event.target.value })
                            }}
                        />
                    </Grid>
                    <Grid sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                        width: "100%",
                        paddingBottom: 2,
                    }}>
                        <InputLabel>{"Repo Link(Optional)"}</InputLabel>
                        <Input name="repoLink"
                            onChange={(event) => {
                                setInputData({ ...inputData, [event.target.name]: event.target.value })
                            }}
                        />
                    </Grid>
                    <Grid sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                        width: "100%",
                        paddingBottom: 2,
                    }}>
                        <InputLabel>{"Demo Link(Optional)"}</InputLabel>
                        <Input name="demoLink"
                            onChange={(event) => {
                                setInputData({ ...inputData, [event.target.name]: event.target.value })
                            }}
                        />
                    </Grid>
                    <Grid sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        bgcolor: 'background.paper',
                        width: "100%",
                        paddingTop: 2,
                    }}>
                        <Button variant="contained" type="submit"
                            onClick={(event) => {
                                onSubmit(inputData)
                            }}>
                            Submit
                        </Button>
                    </Grid>

                </Grid>
            </Box>
        </Modal>
    )
}

export default CreateProjectForm
/*

*/