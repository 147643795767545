
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import VideoBlock from './components/videoBlock';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useScreenSize from '../../hooks/useScreenSize';
import RenderIf from '../../components/RenderIf';
import TechBlock from './components/techBlock';
import RecentBlock from './components/recentBlock';
//  MOVE EDITOR TO A PAGE COMPONENTS
//  MAKE A LAYOUT COMPONENT

const titleText = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
}

const description = {
    alignItems: 'left',
}

const overlay = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgrdounColor: "rgba(0,0,0,.4)",
}


const HomePage = () => {
    const screenSize = useScreenSize();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            flexWrap: 'wrap'
        }}>
            <div style={overlay}></div>
            <VideoBlock>
                <div style={titleText}>
                    <Card sx={screenSize.width > 660 ? {
                        width: '50vw',
                        marginTop: '30vh',
                        marginLeft: '30vh',
                        background: 'transparent',
                        color: 'white',
                        border: "none",
                        boxShadow: "none"
                    } : {
                        width: '100%',
                        marginTop: '30vh',
                        background: 'transparent',
                        color: 'white',
                        border: "none",
                        boxShadow: "none"
                    }}>
                        <Box sx={screenSize.width > 660 ? {
                            display: 'flex',
                            flexDirection: 'vertical'
                        } : {
                            display: 'flex',
                            flexDirection: 'horizontal'
                        }}>
                            <CardContent>
                                <RenderIf condition={screenSize.width > 660}>
                                    <div style={{
                                        borderStyle: 'solid',
                                        borderColor: 'white',
                                        borderWidth: '1px',
                                        height: '100%',
                                        width: '100%',
                                    }}>
                                    </div>
                                </RenderIf>
                            </CardContent>
                            <CardContent>
                                <Typography variant="h3">
                                    Hi there, I'm Tim(Yu) Wang
                                </Typography>
                                <RenderIf condition={screenSize.width <= 660}
                                    children={<div style={{
                                        borderStyle: 'solid',
                                        borderColor: 'white',
                                        borderWidth: '1px',
                                        width: '100%',
                                    }}>
                                    </div>}
                                    fallback={<></>}>

                                </RenderIf>
                                <Typography variant="h6">
                                    Bachelor of Science in Computer Science
                                </Typography>
                                <Typography variant="h6">
                                    Full Stack Software Developer
                                </Typography>
                            </CardContent>
                        </Box>
                    </Card>
                </div>
            </VideoBlock >
            <Grid container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={
                    screenSize.width > 660 ? {
                        marginLeft: '15%',
                        marginRight: '15%'
                    } : {
                        marginLeft: '10%',
                        marginRight: '10%'
                    }
                }

            >
                <Grid item xs={screenSize.width > 660 ? 6 : 12}>
                    <TechBlock></TechBlock>
                </Grid>
                <Grid item xs={screenSize.width > 660 ? 6 : 12}>
                    <RecentBlock></RecentBlock>
                </Grid>
            </Grid>
        </Box>

    )

}

export default HomePage