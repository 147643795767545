import { useEffect, useState } from "react";
import { Player } from 'video-react';
import "../../../../node_modules/video-react/dist/video-react.css";
import ReactAudioPlayer from 'react-audio-player';
import { Typography } from "@mui/material";
import { Storage } from "aws-amplify";
import { Image } from "@aws-amplify/ui-react";

const MEDIA_WIDTH = 300;
const MEDIA_HEIGHT = 200

const MediaBlock = (prop) => {
    const {
        mediaKey,
        mediaContent
    } = prop;

    const [mediaType, setMediaType] = useState("");
    const [content, setContent] = useState(mediaKey ? mediaKey : mediaContent);

    useEffect(() => {
        if (!mediaKey) {
            const regex = /data:(.*?)(\/)/g;
            const matches = [];

            let match;
            while ((match = regex.exec(mediaContent))) {
                matches.push(match[1]);
            }
            console.log("Regex : ", matches);
            setMediaType(matches[0])
        } else {
            const getMedia = async () => {
                const property = await Storage.getProperties(mediaKey, {
                    level: 'protected'
                })

                const regex = /(.*?)(\/)/g;
                const matches = [];

                let match;
                while ((match = regex.exec(property.contentType))) {
                    matches.push(match[1]);
                }
                setMediaType(matches[0])

                const url = await Storage.get(mediaKey, {
                    contentType: property.contentType,
                    level: 'protected'
                })
                setContent(url)
                console.log("Property : ", property)
                console.log("URL : " ,url)
            }
            getMedia();
        }
    }, [mediaKey])

    switch (mediaType) {
        case "image":
            return (
                <Image src={content} 
                    width={MEDIA_WIDTH}
                    height={MEDIA_HEIGHT} 
                />
            )
        case "audio":
            return (
                <ReactAudioPlayer src={content}>
                </ReactAudioPlayer >
            )
        case "video":
            return (
                <Player
                    fluid={false}
                    width={MEDIA_WIDTH}
                    height={MEDIA_HEIGHT}>
                    <source src={content} />
                </Player>
            )
        default:
            return (
                <Typography>
                    No File Selected
                </Typography>
            )
    }
}

export default MediaBlock;