import { useState, useEffect } from 'react';
import { Grid, Box, Toolbar, Button, TextField, FormGroup, FormControlLabel, Typography } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { Auth, Hub } from 'aws-amplify';
import useUser from '../../hooks/useUser';
import RenderIf from '../../components/RenderIf';

const Verify = () => {
    const [verificationCode, setVerificationCode] = useState(null);
    const [toHome, setToHome] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [operations] = useUser();

    const from = ((location?.state?.from) === "/login") ? "/home" : (location?.state?.from || "/home");

    const [errorContext, setErrorContext] = useState("");
    const [errorBox, setErrorBox] = useState(true);
    //Access should be denied if there is no location?.state?.user.

    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "autoSignIn":
                    operations.getUserRole()
                    setToHome(true);
                    break;
                case "autoSignIn_failure":
                    navigate('/login')
                    break;
                default:
                    break;
            }
        });
        return unsubscribe;
    }, [navigate, operations]);

    useEffect(() => {
        const query = location?.state?.query || {};
        if (toHome === true) {
            navigate(from,
                {
                    state: query,
                    replace: true
                })
        }
    }, [toHome, from, navigate,location?.state?.query])

    const handleSubmit = (event) => {
        event.preventDefault();
        confirmSignUp(location?.state?.user)
    }

    async function confirmSignUp() {
        try {
            await Auth.confirmSignUp(location?.state?.user, verificationCode);
        } catch (error) {
            if (error.name === "CodeMismatchException") {
                setErrorContext("Verification Code does not match");
                setErrorBox(true);
            }
            // //console.log('error confirming sign up', error);
        }
    }

    async function resendConfirmationCode(email) {
        try {
            await Auth.resendSignUp(email);
        } catch (err) {
            //console.log('error resending code: ', err);
        }
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                flexWrap: 'wrap',
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Toolbar />
                <Toolbar />

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        display: 'inline-flex',
                        width: '500px',
                        padding: '20px',
                        borderRadius: '25px',
                        backgroundColor: "#272828"
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: 'white'
                        }}
                        alignItems="center"
                        justifyContent="center">
                        <RenderIf condition={errorBox}>
                            <Typography sx={{
                                color: "red"
                            }}>{errorContext}</Typography>
                        </RenderIf>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: 'white'
                        }}
                        alignItems="center"
                        justifyContent="center">
                        <Typography sx={{
                            color: "White"
                        }}>
                            Sign up was successful.
                        </Typography>
                        <Typography sx={{
                            color: "White"
                        }}>
                            An email with verification codes has been sent.
                        </Typography>
                        <Typography sx={{
                            color: "White"
                        }}>
                            To continue, please enter the 6 digits code.
                        </Typography>
                        <Typography sx={{
                            color: "White"
                        }}>
                            If you did not receive the email, please click on resend.
                        </Typography>

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: 'white'
                        }}
                        alignItems="center"
                        justifyContent="center">
                        <FormGroup>
                            <FormControlLabel control={
                                <TextField id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Email Verification Code"
                                    name="verificationCode"
                                    onChange={(event) =>
                                        setVerificationCode(event.target.value)
                                    }
                                    sx={{
                                        input: { color: 'white' }
                                    }} />}
                                label="Email Verification Code"
                                labelPlacement="start" />
                        </FormGroup>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                color: 'white',
                            }}
                            alignItems="center"
                            justifyContent="center">
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    borderWidth: "3px",
                                    borderColor: "white",
                                    marginRight: '15px'
                                }}
                                onClick={() => { resendConfirmationCode(location?.state?.user) }} >
                                Resend
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    borderWidth: "3px",
                                    borderColor: "white",
                                    marginRight: '15px'
                                }}
                                onClick={handleSubmit} >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Box>
        </>
    )
}

export default Verify