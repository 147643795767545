import RenderIf from '../../../components/RenderIf';
import { useState, useMemo, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faBook } from "@fortawesome/free-solid-svg-icons"
import { ButtonGroup, Button, Typography } from "@mui/material"
import * as React from 'react';
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import UploadFile from './UploadFile';
import { Storage } from "@aws-amplify/storage"

const PageEditorAppBar = (PageEditorAppBarProps) => {
    const {
        condition,
        switchMode,
        addTextBlockMethod,
        addImageVideoBlockMethod,
        saveMethod,
        configMethod
    } = PageEditorAppBarProps

    const [mode, setMode] = useState('Read');
    const [uploadProp, setUploadProps] = useState({
        renderCondition: true,
        open: false,
    })

    const handleOnClose = () => {
        setUploadProps({
            ...uploadProp,
            open: false
        })
    }

    const handleOnSubmit = (prop) => {
        const {
            mediaKey
        } = prop;
        addImageVideoBlockMethod({
            mediaKey: mediaKey
        })
        setUploadProps({
            ...uploadProp,
            open: false
        })
    }


    const handleMode = (event, newMode) => {
        if (newMode !== null) {
            setMode(newMode);
        }
        switchMode(newMode)
    };

    const toggleButtonStyle = {
        color: 'white',
        width: "80px",
        backgroundColor: '#3A3A3A'
    }

    return (
        <RenderIf condition={
            condition
        }>
            <UploadFile
                renderCondition={uploadProp.renderCondition}
                onClose={handleOnClose}
                onSubmit={handleOnSubmit}
                open={uploadProp.open} />
            <Box sx={{
                flexGrow: 1,
                width: "100%"
            }}>
                <AppBar position="static"
                    color="transparent"
                    m={0}>
                    <Toolbar>
                        <ToggleButtonGroup
                            value={mode}
                            exclusive
                            onChange={handleMode}
                            aria-label="Mode"
                        >
                            <ToggleButton value="Edit" aria-label="Edit Mode"
                                style={toggleButtonStyle}
                                variant="contained">
                                <FontAwesomeIcon icon={faPen} />
                            </ToggleButton>
                            <ToggleButton value="Read" aria-label="Read Mode"
                                style={toggleButtonStyle}
                                variant="contained">
                                <FontAwesomeIcon icon={faBook} />
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ButtonGroup
                            aria-label="outlined button group"
                        >
                            <Button onClick={() => { addTextBlockMethod() }}
                                style={{
                                    height: "35px",
                                    width: "170px",
                                    margin: "auto"
                                }}
                                startIcon={<FontAwesomeIcon icon={faSquarePlus} />}
                                variant="contained"
                            >

                                <Typography>Add a block</Typography>
                            </Button>
                            <Button onClick={() => {
                                setUploadProps({
                                    ...uploadProp,
                                    open: !uploadProp.open
                                })
                                console.log("upload prop : ", uploadProp)
                            }}
                                style={{
                                    height: "35px",
                                    width: "170px",
                                    margin: "auto"
                                }}
                                startIcon={<FontAwesomeIcon icon={faSquarePlus} />}
                                variant="contained"
                            >
                                <Typography>Image/ Video</Typography>
                            </Button>
                            <Button onClick={() => { saveMethod() }}
                                style={{
                                    height: "35px",
                                    width: "80px",
                                    margin: "auto"
                                }}
                                startIcon={<SaveAltIcon />}
                                variant="contained"
                            >
                                <Typography>Save</Typography>
                            </Button>
                        </ButtonGroup>
                    </Toolbar>
                </AppBar>
            </Box>
        </RenderIf>
    )
}

export default PageEditorAppBar