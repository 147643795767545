import { createContext, useState } from "react";
 

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(
        JSON.parse(localStorage.getItem("my-blog-Auth"))?
            JSON.parse(localStorage.getItem("my-blog-Auth")):{});
    const [persist, setPersist] = useState(
        JSON.parse(localStorage.getItem("persist")) || false)

    //Set cookies here
    return (
        <AuthContext.Provider value={{
            auth,
            setAuth,
            persist,
            setPersist
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;