import { Box, Typography } from "@mui/material"


const RecentBlock = () => {

    const flexBoxStyle = {
        color: 'white',
        width: '100%',
        height: '100%',
        borderRadius: '25px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        zIndex: 10000
    }

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            width: '95%',
            height: '95%',
            color: 'white',
            flexDirection: 'column'
        }}>
            <Typography sx={{
                color: "white",
                fontSize: window.innerWidth <= 480 ? '4vh' : '2rem',
            }}
                gutterBottom={true}
                variant='overline'
                align='left'>
                Recent Projects
            </Typography>
            <Box sx={flexBoxStyle}>
                Coming Soon
            </Box>


        </Box>
    )
}

export default RecentBlock