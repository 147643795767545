import axios from 'axios';
const BASE_URL = `https://bms.ytwang-my-code-blog.net`;
// const BASE_URL = 'http://127.0.0.1'

export default axios.create({
    baseURL: BASE_URL,
    headers:{
        'Access-Control-Allow-Origin': "https://bms.ytwang-my-code-blog.net/*",
        'Content-Type': 'application/json',
    }
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers:{
        'Access-Control-Allow-Origin': "*",
        'Content-Type': 'application/json',
    },
    withCredentials: true
})

export const API_NAME = 'api50c90870';