import { useState } from "react";
import { Box, Tabs, Tab, Toolbar, Button, Typography } from "@mui/material";
import TabPanel from "./components/TabPanel";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const AboutPage = () => {
    const [tabValue, setTabValue] = useState(0);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const flexBoxStyle = {
        color: 'white',
        width: '100%',
        height: '100%',
        borderRadius: '25px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        flexDirection: 'Column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        p: '10%',
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            flexWrap: 'wrap',
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Toolbar />
            <Box backgroundColor='#696b6a'>
                <Tabs value={tabValue}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="basic tabs example">
                    <Tab label="About Me" {...a11yProps(0)} />
                    <Tab label="Resume" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}>
                    <Box sx={flexBoxStyle}>
                        <Typography variant={"h3"}>Tim (Yu) Wang</Typography>
                        <Typography variant={"h6"}>Full Stack Software Developer</Typography>

                        <p>Learning how to build thing from the ground up is what I do everyday.</p>
                        <p>
                            My current interest is going into DevSecOps, so that I fully understand
                            how to build and deploy a secure application.
                        </p>
                        <Box>
                            Technologies I feel proficient at :
                            <ul>
                                <li>Python, C++, Java</li>
                                <li>React, Flask, Spring</li>
                                <li>Docker, SQL</li>
                            </ul>
                        </Box>
                        <Box>
                            Technologies that I'm interested or currently learning :
                            <ul>
                                <li>AWS, Azure</li>
                                <li>ML</li>
                            </ul>
                        </Box>
                        <p>
                            Contact Me :
                        </p>
                        <p>
                            <a href="https://www.linkedin.com/in/yu-wang-57aa88183/" target="_blank">
                                <img src="https://img.shields.io/badge/linkedin-0A66C2?style=for-the-badge&logo=linkedin&logoColor=white" alt="html" />
                            </a>
                        </p>
                        <p>
                            P.S Might try to build a chat bot for contact
                        </p>
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    flexWrap: 'wrap',
                    alignItems: "center",
                    justifyContent: "center",
                    margin: 'auto'
                }}>
                    <a href={'https://s3.us-east-2.amazonaws.com/ytwang-my-code-blog.net/resume.pdf'} download="myResume" target='_blank'
                        style={{
                            marginBottom: '5%'
                        }}>
                        <Button variant="contained">Download Resume</Button>
                    </a>
                    <Document file={
                        {
                            url: 'https://s3.us-east-2.amazonaws.com/ytwang-my-code-blog.net/resume.pdf',
                        }
                    }
                        onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber}
                            renderTextLayer={false}
                            renderMode={"canvas"}
                        />
                    </Document>
                </Box>
            </TabPanel >
        </Box >
    )
}
//
export default AboutPage
