import { forwardRef } from "react";
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDeleteLeft } from "@fortawesome/free-solid-svg-icons"
import RenderIf from "../../../components/RenderIf";

const GridComponentWrapper = forwardRef(({
    style,
    className,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    children,
    removeComponent,
    readOnly,
    ...props }, ref) => {
    return (
        <div style={{ ...style }}
            className={className}
            ref={ref}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onTouchEnd={onTouchEnd}>
            {
                <>
                    {children}
                    <RenderIf condition={readOnly}>
                        <IconButton aria-label="delete" 
                            size="large" 
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }}
                            onClick={()=>{
                                removeComponent(props.keyID)
                            }}
                            >
                            <FontAwesomeIcon icon={faDeleteLeft} />
                        </IconButton>
                    </RenderIf>

                </>
            }
        </div>
    );
})


export default GridComponentWrapper