import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Box, Toolbar, Button, TextField, FormGroup, FormControlLabel, Typography } from '@mui/material';
import { Auth, Hub } from "aws-amplify";
import RenderIf from "../../components/RenderIf"

const SignUp = () => {
    const [formInfo, setFormInfo] = useState({});
    const [passwordError, setPasswordError] = useState(false);
    //Might use for render if, but currently no use
    const navigate = useNavigate();

    const [errorContext, setErrorContext] = useState("");
    const [errorBox, setErrorBox] = useState(true);

    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
            //console.log("Auth event : ",event)
            switch (event) {
                case "signUp":
                    navigate('/emailVerification', { state: { user: data.user.username } })
                    break;
                default:
                    break;
            }
        });
        return unsubscribe;
    }, [navigate]);

    const handleSubmit = (event) => {
        event.preventDefault()
        if (checkIfPasswordEqual(formInfo.password, formInfo.secondPassword) &&
         checkIfPasswordIsValid(formInfo.password)) {
            setPasswordError(false);
            signUp(formInfo)
        } else {
            setErrorContext("Alert: Password does not meet the requirements")
            setErrorBox(true);
            setPasswordError(true);
        }
    }

    async function signUp(formInfo) {
        const {
            username,
            password,
            firstName,
            lastName
        } = formInfo;
        try {
            const name = firstName + " " + lastName;
            await Auth.signUp({
                username: username,
                password: password,
                attributes: {
                    name: name,
                    email: username
                },
                autoSignIn: {
                    enabled: true,
                }
            })
        } catch (error) {
            if(error.name === "UsernameExistsException"){
                setErrorContext("Alert: Email already exist in the system")
                setErrorBox(true);
            }
            //console.log('error signing up:', error.name);
        }
    }

    const checkIfPasswordEqual = (password, secondPassword) => {
        return (password === secondPassword)
    }
    const checkIfPasswordIsValid = (password) => {
        let lower = 0
        let upper = 0
        let number = 0
        let punctuation = 0
        // eslint-disable-next-line
        let specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        for (let i = 0; i < password.length; i++) {
            specialChar.test(password[i]);
            if ("abcdefghijklmnopqrstuvwxyz".match(password[i])) {
                lower += 1
            }
            else if ("ABCDEFGHIJKLMNOPQRSTUVWXYZ".match(password[i])) {
                upper += 1
            }
            else if (("01234456789").match(password[i])) {
                number += 1
            } else if (specialChar.test(password[i])) {
                punctuation += 1
            }
        }
        if (((lower + upper + number + punctuation) > 8) &&
            (punctuation > 0) &&
            (upper > 0) &&
            (number > 0) &&
            (lower > 0)) {
            return true
        }
        return false
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                flexWrap: 'wrap',
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Toolbar />
                <Toolbar />

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        display: 'inline-flex',
                        width: '500px',
                        padding: '20px',
                        borderRadius: '25px',
                        backgroundColor: "#272828"
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: 'white'
                        }}
                        alignItems="center"
                        justifyContent="center">
                        <RenderIf condition={errorBox}>
                            <Typography sx={{
                                color:"red"
                            }}>{errorContext}</Typography>
                        </RenderIf>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: 'white'
                        }}
                        alignItems="center"
                        justifyContent="center">
                        <FormGroup>
                            <FormControlLabel control={
                                <TextField id="outlined-basic"
                                    variant="outlined"
                                    placeholder="First Name"
                                    name="firstName"
                                    onChange={(event) =>
                                        setFormInfo({ ...formInfo, [event.target.name]: event.target.value })
                                    }
                                    sx={{
                                        marginLeft: '5%',
                                        input: { color: 'white' }
                                    }} />}
                                label="First Name"
                                labelPlacement="start" />
                            <FormControlLabel control={
                                <TextField id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Last Name"
                                    name="lastName"
                                    onChange={(event) =>
                                        setFormInfo({ ...formInfo, [event.target.name]: event.target.value })
                                    }
                                    sx={{
                                        marginLeft: '5%',
                                        input: { color: 'white' }
                                    }} />}
                                label="Last Name"
                                labelPlacement="start" />
                            <FormControlLabel control={
                                <TextField id="outlined-basic"
                                    variant="outlined"
                                    placeholder="Email"
                                    name="username"
                                    onChange={(event) =>
                                        setFormInfo({ ...formInfo, [event.target.name]: event.target.value })
                                    }
                                    sx={{
                                        marginLeft: '5%',
                                        input: { color: 'white' }
                                    }} />}
                                label="Email"
                                labelPlacement="start" />
                            <FormControlLabel control={
                                <TextField id="outlined-basic"
                                    error={passwordError}
                                    variant="outlined"
                                    type="password"
                                    placeholder="Enter Password"
                                    name="password"
                                    onChange={(event) =>
                                        setFormInfo({ ...formInfo, [event.target.name]: event.target.value })
                                    }
                                    sx={{
                                        marginLeft: '6%',
                                        input: { color: 'white' }
                                    }}
                                />}
                                label="Password"
                                labelPlacement="start" />
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: '12px',
                                alignItems: "end",
                                justifyContent: "center"
                            }}>
                                <div>Password must be at least 8 characters with</div>
                                <div>1 lower case letter, 1 capital letter, 1 number, </div>
                                <div>and 1 punctuation </div>
                            </Box>
                            <FormControlLabel control={
                                <TextField id="outlined-basic"
                                    error={passwordError}
                                    variant="outlined"
                                    type="password"
                                    placeholder="Enter Password Again"
                                    name="secondPassword"
                                    onChange={(event) =>
                                        setFormInfo({ ...formInfo, [event.target.name]: event.target.value })
                                    }
                                    sx={{
                                        input: { color: 'white' },
                                    }}
                                />}
                                label="Enter Password Again"
                                labelPlacement="start" />
                        </FormGroup>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                color: 'white',
                            }}
                            alignItems="center"
                            justifyContent="center">
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    borderWidth: "3px",
                                    borderColor: "white",
                                    marginRight: '15px'
                                }}
                                onClick={handleSubmit} >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Box>
        </>
    )

}

export default SignUp
