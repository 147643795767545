import '../styles/navbar.css';
import NavTab from './navtab';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { Auth } from 'aws-amplify';

const NavBar = () => {
    const location = useLocation();
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    const navItems = [
        {
            name: 'Home',
            url: './home',
        },
        {
            name: 'About',
            url: './about',
        },
        {
            name: 'Projects',
            url: './projects',
        },
        {
            name: 'Login',
            url: './login',
            query: location?.state,
            showIfLogin: false
        },
        {
            name: 'My Projects',
            url: './myProjects',
            allowedRoles: ['User', 'Admin']
        },
        {
            name: 'Admin',
            url: './admin',
            allowedRoles: ['Admin'],
        }

    ]

    const handleSignOut = () => {
        signOut();
        setAuth({})
        navigate('/')
    }

    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            ////console.log('error signing out: ', error);
        }
    }

    return (
        <AppBar component="nav" sx={{
            zIndex: 1000
        }}>
            <Toolbar>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        flexGrow: 1,
                        display: { xs: 'none', sm: 'block' },
                        fontSize: '25px',
                    }}

                >
                    Tim(Yu) Wang
                </Typography>
                <Box sx={{
                    display: { xs: 'none', sm: 'block' }
                }}>
                    {navItems.map((item) => (
                        <NavTab key={item.name}
                            url={item.url}
                            item={item.item}
                            query={item.query}
                            allowedRoles={item.allowedRoles}
                            showIfLogin={item.showIfLogin}
                        > {item.name}</NavTab>
                    ))}
                </Box>
                <NavTab allowedRoles={['User', 'Admin']}>
                    <div
                        onClick={handleSignOut}>
                        Sign out
                    </div>
                </NavTab>
            </Toolbar>
        </AppBar >
    )
}

export default NavBar

