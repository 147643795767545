import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import RenderIf from '../../../components/RenderIf';
import { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import useAuth from '../../../hooks/useAuth';
import StyledTableCell from '../../../components/StyledTableCell';
import StyledTableRow from '../../../components/StyledTableRow';
import { TableContainer, TableHead, TableRow, Paper, Table, TableBody, Button, Typography } from '@mui/material';

const drawerWidth = "15%";
const ProjectDrawer = (ProjectDrawerProps) => {
    const {
        blogs,
        addBlog,
        displayBlog,
        allowedUser
    } = ProjectDrawerProps

    const [projectBlogs, setProjectBlogs] = useState([]);
    const [newBlogTitle, setNewBlogTitle] = useState("");
    const [maxIndex, setMaxIndex] = useState(0);
    const [addBlogInputText, setAddBlogInputText] = useState(false);
    const { auth } = useAuth();

    useEffect(() => {
        if (blogs) {
            let sortedBlogs = blogs.sort((blog1,blog2)=>{return Number(blog1.ArticleIndex) > Number(blog2.ArticleIndex)})
            const articles = sortedBlogs.filter((blog)=>blog.ArticleIndex>=0);
            setProjectBlogs(articles)
        }
    }, [blogs])

    // const container = window !== undefined ? () => window().document.body : undefined;

    const AddBlogToDrawer = (props) => {
        const {
            ArticleTitle,
            ArticleIndex
        } = props

        setProjectBlogs([...projectBlogs, {
            ArticleIndex: ArticleIndex,
            ArticleTitle: ArticleTitle
        }])
    }
    

    return (
        <Drawer
            variant="permanent"
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth,
                    backgroundColor: 'rgba(0,0,0,0.5)',
                },
                zIndex: 500,
                width: '20%'
            }}
        // container={container}
        >
            <Toolbar />
            <TableContainer component={Paper}>
                <Table aria-label="customized table" sx={{
                    borderColor: 'black'
                }}>
                    <TableHead>
                        <TableRow >
                            <StyledTableCell size='small'>
                                Project Contents
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(projectBlogs).map(([key, value]) => {
                            if (maxIndex < Number(value.ArticleIndex)) {
                                setMaxIndex(Number(value.ArticleIndex))
                            }
                            return (
                                <RenderIf key={key} condition={value.ArticleTitle}>
                                    <StyledTableRow >
                                        <StyledTableCell size='small' align="left">
                                            <Button onClick={() => {
                                                displayBlog({ newArticleIndex: value.ArticleIndex })
                                            }}>
                                                <Typography sx={{
                                                    color: 'white',
                                                    textDecoration: 'underline'
                                                }}
                                                    variant="outlined"
                                                >
                                                    {value.ArticleTitle}
                                                </Typography>
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </RenderIf>
                            )
                        })}
                        <RenderIf condition={ (auth?.roles? auth?.roles.includes('User'):false) && auth?.user === allowedUser}>
                            <StyledTableRow key={"add"}>
                                <StyledTableCell size='small' align="left" >
                                    <RenderIf condition={addBlogInputText}
                                        fallback={
                                            <Button onClick={() => {
                                                setAddBlogInputText(true);
                                            }}
                                                sx={{
                                                    height: '20px',
                                                    width: '100%',
                                                    backgroundColor: 'rgba(0,0,0,0)',
                                                    border: 'none',
                                                    color: 'white'
                                                }}>
                                                <AddIcon sx={{
                                                    marginRight: '20px'
                                                }} />
                                                <Typography>Add Blog</Typography>
                                            </Button>
                                        }>
                                        <TextField
                                            variant="standard"
                                            placeholder="enter new title"
                                            autoFocus
                                            sx={{
                                                "&::placeholder": {
                                                    opacity: 1,
                                                },
                                                input: {
                                                    color: 'white',
                                                },
                                                width: "70%",
                                            }}
                                            onChange={(event) => {
                                                setNewBlogTitle(event.target.value);
                                            }}

                                        />
                                        <Button
                                            onClick={() => {
                                                addBlog({
                                                    newBlogTitle: newBlogTitle,
                                                    ArticleIndex: (maxIndex + Number(1))
                                                });
                                                AddBlogToDrawer({
                                                    ArticleIndex: (maxIndex + Number(1)),
                                                    ArticleTitle: newBlogTitle
                                                })
                                                setNewBlogTitle("");
                                                setAddBlogInputText(false);
                                            }}
                                            sx={{
                                                backgroundColor: 'rgba(0,0,0,0)',
                                                color: 'white',
                                                zIndex: 6000,
                                            }}
                                            children={(<AddIcon size="small" />)}
                                        >
                                        </Button>
                                    </RenderIf>
                                </StyledTableCell>
                            </StyledTableRow>
                        </RenderIf>
                    </TableBody>
                </Table>
            </TableContainer>
        </Drawer >
    )
}

export default ProjectDrawer
