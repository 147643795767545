import useAxiosFunction from './useAxiosFunction';
import { useState } from 'react';
import useAxiosPrivate from "./useAxiosPrivate";
import { defaultContent } from "../helper/defaultContent";
import useAuth from "./useAuth";
import uuid from "react-uuid";
import { API } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { Blogs } from '../models';

//Either get all, or get a user's project overview
// /getAllProject or getUserProjects
const usePage = () => {

    // const [response, status, error, loading, axiosFetch] = useAxiosFunction();
    const { auth } = useAuth();
    const [resposne, setResponse] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    const operations = {
        addProject: async (addProjectProps) => {
            const {
                projectTitle,
                firstBlogTitle,
                author,
                authorsub,
                projectDescription,
                repoLink,
                demoLink
            } = addProjectProps;
            try {
                const projectID = uuid();
                await DataStore.save(
                    new Blogs({
                        "ProjectID": projectID,
                        "ProjectTitle": projectTitle,
                        "ArticleIndex": -1,
                        "Content": null,
                        "Author": author,
                        "AuthorSub" : authorsub,
                        "ProjectDescription": projectDescription,
                        "demoLink": demoLink ? demoLink : "",
                        "githubLink": repoLink ? repoLink : "",
                    })
                );
                await DataStore.save(
                    new Blogs({
                        "ProjectID": projectID,
                        "ProjectTitle": projectTitle,
                        "ArticleIndex": 0,
                        "Content": defaultContent,
                        "Author": author,
                        "AuthorSub" : authorsub,
                        "ArticleTitle": firstBlogTitle
                    })
                )
                return projectID;
            } catch (err) {
                //console.log(err)
            }

        },
        getProject: async (projectProps) => {
            const {
                ProjectID
            } = projectProps;
            await DataStore.query(Blogs, (blogs) => blogs.and(blogs => [
                blogs.ProjectID.eq(ProjectID)
            ])).then((res) => {
                setResponse(res)
            });
        },
        updateProject: async (projectProps) => { // Need rework
            const {
                ProjectID,
                newProjectTitle,
            } = projectProps;
            const original = await DataStore.query(Blogs,
                (blogs) => blogs.and(blogs => [
                    blogs.ProjectID.eq(ProjectID),
                    blogs.AuthorSub.eq(auth.userSub),
                    blogs.ArticleIndex.eq(-1)
                ])).catch((err) => {
                    //console.log(err)
                })
            await DataStore.save(
                Blogs.copyOf(original, updated => {
                    updated.ProjectTitle = newProjectTitle
                }).catch((err) => {
                    //console.log(err)
                })
            );
        },
        addBlogToProject: async (projectProps) => {
            const {
                ProjectID,
                ArticleIndex,
                author,
                authorSub,
                blogTitle,
            } = projectProps;
            await DataStore.save(
                new Blogs({
                    "ProjectID": ProjectID,
                    "ProjectTitle": "",
                    "ArticleIndex": ArticleIndex,
                    "Content": defaultContent,
                    "Author": author,
                    "AuthorSub": authorSub,
                    "ArticleTitle": blogTitle
                })
            );
        },
        updateBlog: async (projectProps) => {
            const {
                ProjectID,
                ArticleIndex,
                Author,
                AuthorSub,
                Title,
                Content
            } = projectProps;
            const original = await DataStore.query(Blogs,
                (blogs) => blogs.and(blogs => [
                    blogs.ProjectID.eq(ProjectID),
                    blogs.ArticleIndex.eq(Number(ArticleIndex)),
                    blogs.Author.eq(Author),
                    blogs.AuthorSub.eq(AuthorSub),
                ])).catch((err) => {
                    //console.log(err)
                })
            await DataStore.save(
                Blogs.copyOf(original[0], updated => {
                    updated.ArticleTitle = Title;
                    updated.Content = Content;
                })
            ).catch((err) => {
                //console.log(err)
            });
        },
        removeBlogs: async (projectProps) => {
            const {
                projectBlogs,
                author,
                authorSub
            } = projectProps;

            const res = projectBlogs.map(async (blogData) => {
                await DataStore.delete(Blogs, (blogs) =>
                    blogs.and(blogs => [
                        blogs.ProjectID.eq(blogData.ProjectID),
                        blogs.ArticleIndex.eq(Number(blogData.ArticleIndex)),
                        blogs.Author.eq(author),
                        blogs.Author.eq(authorSub)
                    ])
                ).catch((err) => {
                    //console.log(err)
                });
            })
            return await Promise.all(res);
        }
    }

    return [resposne, error, loading, operations]
}

export default usePage;