import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { cloneElement } from 'react';

const UserList = ({users=[]}) => {

    const generate = (element) => {
        return users.map((value) => {
            return (cloneElement(element, {
                key: value.user,
            }))
        })
    }

    return (
        <>

            <List>
                {generate(
                    <ListItem>
                        <ListItemText
                            primary="Single-line item"
                            secondary={'Secondary text'}
                        />
                    </ListItem>
                )}
            </List>
        </>
    )
}

export default UserList