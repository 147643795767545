import { WidthProvider, Responsive } from "react-grid-layout";
import GridComponent from "./GridComponent";
import GridComponentWrapper from "./GridComponentWrapper";
import uuid from 'react-uuid';
import { useState, useMemo, useEffect } from 'react';
import useCtrlS from "../../../hooks/useCtrlS";
import useAuth from "../../../hooks/useAuth";
import PageEditorAppBar from "./PageEditorAppBar";
import MediaBlock from "./mediaBlock";

const PageLayout = (PageLayoutProp) => {
    const {
        allowedRoles,
        response,
        onSave
    } = PageLayoutProp
    // layout is an array of objects, see the demo for more complete usage
    // const layout = [
    //         { i: "a", x: 0, y: 0, w: 1, h: 2 }
    //     ]
    const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);
    const { auth } = useAuth();
    const [layout, setLayout] = useState({ lg: [] });
    const [draggable, setDraggable] = useState(true);
    const [componentData, setComponentData] = useState({});

    const [projectDetail, setProjectDetail] = useState({});
    //Read only is set to true
    const [readOnly, setReadOnly] = useState(true);

    useEffect(() => {
        if (response) {
            let JSONContent = JSON.parse(response.Content)
            let newlayout = JSONContent.map((item) => {
                return {
                    "i": item.i,
                    "x": item.x,
                    "y": item.y,
                    "w": Number(item.w),
                    "h": Number(item.h),
                    "minW": 3,
                    "minH": 3,
                }
            })
            let content = Object.fromEntries(
                JSONContent.map((item) => {
                    if (item.i !== "") {
                        return ([item.i, item.Content])
                    }
                })
            )
            setLayout({ ...layout, lg: [...newlayout] })
            setComponentData({ ...content })
            setProjectDetail({
                ArticleTitle: response.ArticleTitle,
                Author: response.Author
            })
            if (auth.user === response.Author) {
                setReadOnly(false)
            } else {
                setReadOnly(true)
            }
        }
    }, [response])

    //Need axios to connect to back-end

    const addComponent = () => {
        let newUUID = uuid()
        setLayout(
            {
                lg:
                    layout.lg.concat({
                        i: newUUID,
                        x: (layout.lg.length * 2) % (12),
                        y: Infinity,
                        w: 6,
                        h: 6,
                        minW: 3,
                        minH: 3,
                    })
            }
        )
        setComponentData(
            {
                ...componentData,
                [newUUID]: "<p>Start Here</p>"
            }
        )
    }

    const addImageVideoComponent = (prop) => {
        const {
            mediaKey
        } = prop;
        let newUUID = uuid()
        setLayout(
            {
                lg:
                    layout.lg.concat({
                        i: newUUID,
                        x: (layout.lg.length * 2) % (12),
                        y: Infinity,
                        w: 6,
                        h: 6,
                        minW: 3,
                        minH: 3,
                    })
            }
        )
        setComponentData(
            {
                ...componentData,
                [newUUID]: {
                    type: "media",
                    key: mediaKey
                }
            }
        )
    }

    const removeComponent = (id) => {
        setLayout({
            lg: layout.lg.filter((item) => {
                if (item.i != id) return item
            })
        });
    }

    const toggleReadOnly = (mode) => {
        if (mode === "Read")
            setReadOnly(true)
        else if (mode === "Edit")
            setReadOnly(false)
    }

    const updateComponentData = (keys, value) => {
        setComponentData(
            {
                ...componentData,
                [keys]: value
            }
        )
    }

    const handleResizeStop = (layout, oldItem, newItem) => {
        setLayout((prevLayout) => {
            return {
                lg:
                    (prevLayout.lg.map((item) =>
                        (item.i === newItem.i)
                            ? { ...item, w: newItem.w, h: newItem.h } : item

                    ))
            }
        }
        );
    };

    const handleHeightChange = (i, heightIncrement) => {
        setLayout((prevLayout) => {
            return {
                lg: prevLayout.lg.map((item) => {
                    return (item.i === i) ? { ...item, h: (item.h + heightIncrement) } : item
                }
                )
            }
        }
        )
    }

    const handleLayoutChange = (layout) => {
        setLayout(
            {
                lg: layout
            }
        )
    }

    useCtrlS(() => {
        onSave({
            layout: layout,
            content: componentData,
            title: projectDetail.ArticleTitle,
            author: projectDetail.Author,
            authorSub: response.AuthorSub
        })
    })

    return (
        <div style={{
            display: 'inline-block',
            width: '100%',
            flexDirection: "column",
        }}>
            <PageEditorAppBar condition={auth?.roles ?
                (auth?.roles?.find(role => allowedRoles?.includes(role))
                    && (response?.AuthorSub ? (auth?.userSub === response.AuthorSub) : false)
                )
                : false}
                switchMode={toggleReadOnly}
                addTextBlockMethod={addComponent}
                addImageVideoBlockMethod={addImageVideoComponent}
                saveMethod={() => {
                    onSave({
                        layout: layout,
                        content: componentData,
                        title: response.ArticleTitle,
                        author: response.Author,
                        authorSub: response.AuthorSub
                    })
                }}
                configMethod={handleHeightChange}
            />
            <ResponsiveReactGridLayout
                className="layout"
                layouts={layout}
                cols={{ lg: 24, md: 16, sm: 12, xs: 8, xxs: 4 }}
                rowHeight={25}
                isDraggable={draggable && !readOnly}
                isResizable={draggable && !readOnly}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 360 }}
                onResizeStop={handleResizeStop}
                onLayoutChange={handleLayoutChange}
            >
                {
                    layout.lg.map((item) => {
                        if (componentData[item.i].type === "media") {
                            return (
                                <GridComponentWrapper
                                    key={item.i}
                                    style={{
                                        display: 'inline',
                                        overflow: 'hidden',
                                        backgroundColor: '#4A4A4A',
                                        color: 'white'
                                    }}
                                    removeComponent={removeComponent}
                                    keyID={item.i}
                                >
                                    <MediaBlock mediaKey={componentData[item.i].key} />
                                </GridComponentWrapper>
                            )
                        }
                        return (
                            <GridComponentWrapper
                                key={item.i}
                                style={{
                                    display: 'inline',
                                    overflow: 'hidden',
                                    backgroundColor: '#4A4A4A',
                                    color: 'white'
                                }}
                                removeComponent={removeComponent}
                                keyID={item.i}
                            >
                                <GridComponent
                                    keyID={item.i}
                                    content={componentData[item.i]}
                                    draggable={setDraggable}
                                    onOverflow={handleHeightChange}
                                    updateComponent={updateComponentData}
                                    readOnly={readOnly}
                                />
                            </GridComponentWrapper>
                        )
                    })
                }
            </ResponsiveReactGridLayout>
        </div>
    );
}

export default PageLayout